import React from 'react';
import { Helmet } from 'react-helmet';

const Profile = () => (
  <Helmet>
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "ProfilePage",
          "dateCreated": "2024-08-10T12:34:00-05:00",
          "dateModified": "2024-08-10T12:34:00-05:00",
          "mainEntity": {
            "@type": "Person",
            "name": "Daniel Bechenea",
            "alternateName": "danielbechenea",
            "description": "Product Security Manager and OSCP Certified Penetration Tester",
            "image": "https://danielbechenea.com/daniel.jpg",
            "sameAs": [
              "https://www.linkedin.com/in/danielbechenea/"
            ]
          }
        }
        `}
    </script>
  </Helmet>
);

export default Profile;
